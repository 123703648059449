<template>
  <main class="page-points page-show-point p-0 d-flex flex-column">
    <div class="nav-container">
      <div class="container">
        <ul class="nav nav-pills nav-fill">
          <li class="nav-item">
            <router-link class="nav-link active" :to="{ name: 'points.show' }" aria-current="page">Dati</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.edit' }">Modifica</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.pick' }">Punto</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :class="{ 'disabled': !point.hasContours }" :to="{ name: 'points.contours' }">Contorni</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'points.notes' }">Contenuti</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex-grow-1 container py-5">
      <div class="table-responsive mb-5">
        <table class="table table-condensed visea-info-table">
          <tbody>
          <tr>
            <td class="title">Titolo Finestra</td>
            <td>{{ point.windowTitle }}</td>
          </tr>
          <tr>
            <td class="title">Titolo Particolare</td>
            <td>{{ point.title }}</td>
          </tr>
          <tr>
            <td class="title">Anno</td>
            <td>{{ point.year }}</td>
          </tr>
          <tr>
            <td class="title">Template</td>
            <td>{{ point.templateId | templateId }}</td>
          </tr>
          <tr>
            <td class="title">Colore</td>
            <td>
              <div class="colour-row">
                <ColouredSquare :colour="point.colour | colour" /> <span>{{ point.colour | colourName }}</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="title">Contorni</td>
            <td>{{ point.hasContours ? 'Attivi' : 'Non attivi' }}</td>
          </tr>
          <tr>
            <td class="title">Livello</td>
            <td>{{ module.name }}</td>
          </tr>
          <tr>
            <td class="title">Progetto</td>
            <td>{{ project.name }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>
<script>

import rolesMixin from '@/mixins/roles';
import pageableMixin from '@/libs/Pagination/mixins/pageable';
import templateIdFilter from '@/filters/templateIdFilter';
import colourFilter, { colourNameFilter } from '@/filters/colourFilter';

export default {
  name: 'point-show',
  mixins: [rolesMixin, pageableMixin],
  components: {
    ColouredSquare: () => import('@/views/components/Form/Widgets/ColouredSquare'),
  },
  filters: {
    templateId: templateIdFilter,
    colour: colourFilter,
    colourName: colourNameFilter,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
    point: {
      type: Object,
      required: true,
    },
  },
};

</script>

<style lang="scss">

.colour-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 10px;
  }
}
</style>
